<template>
    <div>
        <div class="loading" v-if="fetchData">
            <load-data></load-data>
        </div>
        <div class="evaluate" v-else>
            <b-container fluid>
                <br />
                <b-row>
                    <b-col md="12">
                        <div class="loading" v-if="StudentExamSheet">
                            <load-data></load-data>
                        </div>
                        <div v-else>
                            <!-- <div v-if="studentSheet.length != 0"> -->
                            <div>
                                <div class="closeBtn mb-2 text-right">
                                    <b-button variant="danger" size="sm" @click="close()">Close</b-button>
                                </div>
                                <h4 class="text-center" style="font-size: 24px; font-weight: 900">
                                    Evaluation Sheet for: <span class="text-info">{{ exam.name }}</span>
                                    <br>
                                    <div v-if="scoreNotUpdated" class="text-danger">Exam score is not updated yet, please
                                        check back
                                        later.</div>
                                    <span v-if="totalScore"> Total score : {{ totalScore }}/{{ maxScore }}</span>
                                    <br>
                                    <span v-if="totalScore"> {{ (Math.floor(totalScore * 10000 / maxScore) / 100) }}
                                        %</span>
                                </h4>

                                <div class="studentSheet mt-3" v-for="(sheet, sheetIndex) in studentSheet"
                                    :key="sheetIndex">
                                    <b-card header-tag="header" class="mt-3" footer-tag="footer" v-if="sheet.question">
                                        <template #header>
                                            <div class="question">
                                                <!-- <p>Ques: {{ sheet.question }} </p>-->
                                                Ques {{ sheet.questionNumber }}:
                                                <span v-for="(qData, qdIndex) in sheet.question" :key="qdIndex"
                                                    class="mr-1">
                                                    
                                                    <span class="big-font" v-if="qData.type == 'text'"
                                                        v-html="latexCodeToMathLive(qData.value)"></span>
                                                </span>
                                                <span v-if="(sheet.maximum_mark > 0)">(Score:
                                                    {{ sheet.maximum_mark }})</span>
                                            </div>
                                        </template>
                                        <b-card-body>
                                            <div class="answer d-flex justify-content-between align-items-baseline">
                                                <div class="ans">
                                                    <!--<p v-if="(sheet.answer != null)">Ans: {{ sheet.answer }}</p>-->
                                                    <p v-if="(sheet.choice_id)">Choice: <span
                                                            :style="sheet.choices[sheet.choice_id].is_right_choice == 1 ? 'color:green;' : 'color:red;'">
                                                            {{ sheet.choices[sheet.choice_id].choice_no }}) {{
                                                                sheet.choices[sheet.choice_id].name }}</span></p>
                                                </div>
                                                <div class="marks">
                                                    <p>{{ sheet.marks }}</p>
                                                </div>
                                            </div>
                                            <hr />
                                            <div class="d-flex justify-content-between flex-wrap">
                                                <div class="questionAttachments">
                                                    <p v-if="sheet.choice_id && sheet.choices[sheet.choice_id].is_right_choice != 1">Ans:
                                                        {{ sheet.rightChoiceNo }}) {{ sheet.rightChoice }}
                                                    </p>

                                                </div>

                                            </div>
                                        </b-card-body>
                                    </b-card>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>
<script>
import axios from "axios";
export default {
    created() {
        this.fetchData = true;
        this.getExam();
        this.userAttachments = this.$store.getters.getAPIKey.userAttachments;
    },
    data() {
        return {
            fetchData: false,
            StudentExamSheet: false,
            totalScore: 0,
            maxScore: 0,
            userAttachments: "",
            currentMark: "0",
            exam: {},
            students: [],
            examQuestions: [],
            orquestiongroups: {},
            orGroupScores: {},
            studentSheet: [],
            marksBtn: {
                text: "Submit",
                disabled: false,
            },
            enableCoScoreView: false,
            courseOutcomes: [],
            participant: null,
            totalCoScore: 0,
            maxCoScore: 0,
            scoreNotUpdated: true,

        };
    },
    methods: {
        latexCodeToMathLive(codeText) {
            if (codeText == null || codeText == "") return "";
            return codeText.replace(
                /\$\$(.*?)\$\$/g,
                '<math-field class="p-0" style="border: none;" readonly>$1</math-field>'
            );
        },
        close() {
            if (this.$route.params.type == 0) {
                this.$router.push('/publicexam/dashboard');
            }
            else {
                this.closeCurrentTab();
            }
        },
        getExam() {
            let publicexam = this.$route.params.type == 1 ? '' : '/publicexampublicuser';
            const examId = this.$route.params.exam_id;
            const url = this.$store.getters.getAPIKey.mainAPI +
                `${publicexam}/publicexams/${examId}`;
            axios
                .get(url)
                .then((response) => {
                    this.exam = {};
                    this.exam = response.data;
                    this.getExamQuestions();
                    response;
                })
                .catch((error) => {
                    error;
                });
        },
        getStudentSheet() {
            let studentSheet = this.$route.params.type == 1 ? '' : '/publicexampublicuser';
            const url =
                this.$store.getters.getAPIKey.mainAPI +
                `${studentSheet}/publicexamanswers/studentscore?public_exam_id=${this.$route.params.exam_id}`;
            axios
                .get(url)
                .then((response) => {
                    



                    this.examQuestions = [];
                 //   this.studentSheet = [];
                    this.examQuestions = response.data.questions;
                    if (this.examQuestions.length != 0) {
                        this.examQuestions.forEach((ques) => {
                            let qChoices = {};
                            let rightChoice = '';
                            let rightChoiceNo = '';
                            if (ques.choices.length > 0) ques.choices.forEach(choice => {
                                qChoices[choice.id] = choice;
                                if (choice.is_right_choice == 1) {
                                    rightChoice = choice.name,
                                        rightChoiceNo = choice.choice_no
                                }
                            });
                            this.studentSheet.push({
                                questionId: ques.id,
                                studentId: "",
                                questionNumber: ques.question_no,
                                question: ques.question,
                                maximum_mark: ques.maximum_mark,
                                answer: "",
                                marks: "",
                                attachment: [],
                                files: ques.files,
                                studentFiles: [],
                                choices: qChoices,
                                choice_id: null,
                                rightChoice: rightChoice,
                                rightChoiceNo: rightChoiceNo,
                            });
                            this.maxScore += Number(ques.maximum_mark);
                        });
                    }


                    let answers = response.data.answers;
                    if (answers.length != 0) {
                        answers.forEach((answer) => {
                            let quesId = answer.examquestion_id;
                            this.studentSheet.forEach((checkAns) => {
                                if (quesId == checkAns.questionId) {
                                    checkAns.answer = answer.answer;
                                    checkAns.studentId = answer.student_id;
                                    checkAns.choice_id = answer.choice_id;
                                    checkAns.studentFiles = answer.files;
                                    if (answer.score != null) {
                                        this.scoreNotUpdated = false;
                                        let marks = answer.score;
                                        checkAns.marks =
                                            marks != null ?
                                                marks % 1 == 0 ?
                                                    Math.floor(marks) :
                                                    parseFloat(marks).toFixed(2) :
                                                null;
                                        this.totalScore = this.totalScore + parseFloat(answer.score);
                                    }
                                }
                            });
                        });
                    }

                    this.StudentExamSheet = false;
                    this.fetchData = false;
                })
                .catch((error) => {
                    error;
                });
        },
        async getExamQuestions() {
           
            this.getStudentSheet();
        },
        clearResponse() {
            this.marksBtn.text = "Submit";
            setTimeout(() => {
                this.getExamQuestions();
                this.marksBtn.disabled = false;
            }, 3000);
        },
    },
    watch: {
        studentSheet: {
            deep: true,
            handler: async function () {
                let answerMarks;
                // if (this.studentSheet.length != 0) {
                await this.studentSheet.forEach((mark) => {
                    // if (mark.marks == "") {
                    //   this.currentMark = "0";
                    // }
                    if (mark.marks != "") {
                        answerMarks = mark.marks;
                    }
                });
                if (answerMarks != undefined) {
                    this.currentMark = this.currentMark + answerMarks;
                }
            },
        },
    },
};
</script>

<style>
.evaluate .col-md-12 {
    height: 600px;
    overflow-y: scroll;
}

.coScore-table {
    width: 100%;
}

.coScore-table table,
th,
td {
    border: 1px solid;
}
</style>
